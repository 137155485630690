.SelectorContainer {
  display: flex;
  flex-direction: column;
  width: 350px;
}

.ExpressContainer {
  display: flex;
  flex-direction: column;
  border-radius: 11px;
  border: solid 1px #6d7278;
  padding: 26px 15px 15px;
}

.TraditionalContainer {
  display: flex;
  flex-direction: column;
  border-radius: 11px;
  border: solid 1px #6d7278;
  padding: 26px 15px 15px;
}

.Button {
  border-radius: 8px;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  outline: none;
}


.AppleButton {
  background-image: url('../../../Assets/images/png/butonPay.png');
  background-repeat: no-repeat;
  background-position: center;
  height: 45px;
}

.PaypalButton {
  background-image: url('../../../Assets/images/png/buttonPayPal.png');
  background-repeat: no-repeat;
  background-position: center;
  height: 45px;
}

.TitleExpress {
  margin-top: -30px;
  align-self: center;
  background:white;
  font-family: 'ModernEra';
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6d7278;
  width: 120px;
}

.TraditionalContainer {
  margin-top: 23px;
  display: flex;
  flex-direction: column;
}

.TitleTraditional {
  margin-top: -30px;
  align-self: center;
  background:white;
  font-family: 'ModernEra';
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6d7278;
  width: 150px;
}

.TraditionalButton{  
  margin-top: 10px;
  border-radius: 8px;
  border: solid 1px #0752f9;
  padding: 14px;
  text-align: center;
  font-family: 'ModernEraBold';
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0752f9;
  cursor: pointer;
  outline: none;
}

.PaymentMethosImg {
  display: flex;
  justify-content: center;
  margin: 33px 0 18px;
}

.Image {
  margin-left: 15px;
  width: 50px;
  height: 23px;
}

@media (max-width: 350px) {
	.SelectorContainer {
	  width: 300px;
	}
}