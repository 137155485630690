.SuccessContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Logo {
  display: flex;
  margin: 70px 0 30px;
}

.TryAgain{
  outline: 0;
  border-radius: 8px;
  border: 0;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  padding: 14px 30px 15px;
  background-color: #0752f9;
  font-family: ModernEra;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  margin-top: 30px;
}

.SuccessContainer h1 {
  font-family: CooperBT;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
}


.SuccessContainer h2 {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2a2a2a;
  margin: 0
}

.SuccessContainer .Blue {
  color: #0752f9;
}

@media(min-width: 700px){
  .SuccessContainer {
    margin-top: 50px;
  }
}
