  .PSEContainer {
	font-family: 'ModernEra';
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.75;
	letter-spacing: normal;
	color: #252525;
  }

  .Form{
	margin-top: 20px;
	margin-bottom: 20px;
  }
  
  .SelectorContainer{
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 0 30px;
  }
  
  .TopicSelector{
	display: flex;
	flex-direction: column;
	outline: none;
	width: 100%;
	border-radius: 21px;
	box-shadow: inset 0 0 20px 0 rgba(169, 192, 221, 0.4);
  }

  .Input {
	width: 80%;
	font-size: 16px;
	border-width: 0 0 2px 0;
    border-color: #c8ccd4;
  }

  .InputContainer {
	width: 20%;
    margin-right: 10px;
    border-radius: 12px;
    padding: 0 6px;
  }
  
  .TextTema {
	height: 58px;
	text-align: center;
	display: flex;
	align-items: center;
	margin: 0 5%;
	font-size: 16px;
  }
  
  .TextTema > img{ 
	margin-left: auto;
  }
  
  .TopicSelector > ul, .TopicDesktopSelector > ul {
	list-style: none;
	padding: 0;
	margin: 0;
	transition: max-height .5s ease 0s;
  }
  .TopicSelector > ul > li , .TopicDesktopSelector > ul > li{
	padding: 8px 12px;
	transition: max-height .5s ease 0s;
  }
  
  .TopicSelector > ul > li:hover {
	background-color: rgba(0, 0, 0, 0.14);
	cursor: pointer;
  }

  .Scroll {
	overflow-y: auto;
	max-height: 244px;
  }

  .Error {
	color: red;
	margin: 0 10px 0;
    text-align: left;
	font-family: "ModernEra";
  }

  .SelectorWrapper {
	display: flex;
	width: 350px;
  }

  @media (max-width: 350px) {
	.SelectorWrapper {
	  width: 300px;
	}
  }
 
  
