.MainContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
}

.Banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: #101d3b;
  z-index: 2;
  width: 100%;
  height: 80px;
}

.Banner > img {
  width: 140px;
  height: 30px;
  margin-left: 20px;
}

.Discount {
  margin: 0;
  font-family: ModernEra;
  font-size: 15px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #94b7fe;
  margin: 0 10px;
  max-width: 268px;
}


@media (min-width: 700px) {
  .Banner, .MainContainer {
    height: 60px;
  }

  .Banner {
    flex-direction: row;
  }

  .Banner > img {
    width: 157px;
    height: 33px;
    margin-left: 20px;
  }

  .Discount {
    max-width: 100%;
  }
}
