.SpeiContainer {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
   font-family: 'ModernEra';
   font-size: 16px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: normal;
   text-align: center;
   color: #2a2a2a;
   margin-top: 30px;
   max-width: 350px;
}

.SpeiItem {
  margin: 0
}

.SpeiItem b{
   font-family: 'ModernEraBold';
}

.SpeiReference{
    margin: 0;
}

.SpeiTitle {
    margin: 16px 0 0 0;
}
  
.SpeiData {
  margin-top: 20px;
}

.Notice {
  margin: 20px 0 0 0;
}

.RefContainer {
  margin: 16px 0 0 0;
  border-radius: 8px;
  background-color: #ffe8c5;
  padding: 15px 8px;
}

.Buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ButtonDownload {
  border-radius: 8px;
  border: solid 1px #0752f9;
  padding: 14px 30px;
  margin-top: 30px;
  cursor: pointer;
}
