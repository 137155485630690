.CounterContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px 0;
    border-radius: 10px;
    background-color: #e2e2e2;
    font-family: 'ModernEra';
    font-size: 13px;
    margin-top: 40px;
    color: #4971a8;
    max-width: 316px;
    width: 100%;
  }
  
  .CounterContainer > p{
    margin: 0 10px;
    font-family: ModernEra;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    color: #1e1e1e;
  }
  
  .TimeExpired {
    border-radius: 10px;
    background-color: #fcf6b5;
  }
  
  .Counter {
    margin-top: 16px !important;
  }
  
  .PopupCard {
    padding: 24px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 420px;
    width: 420px;
    margin: auto 24px;
  }
  
  .PopupTitle {
    font-family: ModernEraBold;
    font-size: 24px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-top: 40px;
  }
  
  .PopupDesc {
    font-family: ModernEra;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 24px;
  }
  
  .PopupBtn {
    cursor: pointer;
    border: none;
    outline: none;
    padding: 8px;
    background-color: #0752f9;
    font-family: ModernEra;
    font-size: 22px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border-radius: 8px;
    margin-top: 24px;
    max-width: 80%;
    width: 332px;
  }
  
  .TimerIconAlert {
    object-fit: contain;
    width: 80px;
    height: 80px;
    margin-top: 16px;
  }
  
  @media(max-width: 350px){
    .CounterContainer {
      max-width: 280px;
    }
  }
  
  @media(min-width: 700px){
    .CounterContainer {
      max-width: 408px;
      padding: 15px 50px;
    }
  
    .PopupCard {
      margin: 0;
    }
  }