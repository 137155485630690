.PopUpFullModal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10000;
    height: 100%;
    background: #807d7dd9;
    bottom: 0;
    overflow-y: scroll;
}