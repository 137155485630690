
.Right{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  order: 2;
}

.Left {
  display: none;
}

.Container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  width: 100%;
  align-items: center;
}

.SquareContainer{
  display: none;
}


.SubmitButton{
  outline: 0;
  border-radius: 8px;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  background-color: #0752f9;
  padding: 14px 30px 15px;
  font-family: 'ModernEra';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  cursor: pointer;
}

.PixelAffiliate {
  width: 1px;
  height: 1px;
  display: none;
}

.FormSubmitButton {
  outline: none;
  margin-top: 30px;
  min-width: 156px;
}

.SeparatorExtra {
  border: solid 1px #e0e0e0;
  margin: 30px 0 20px;
  width: 100%;
  max-width: 345px;
}

@media (min-width: 700px) {

  .Container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .Right {
    width: 50%;
    order: 1;
    min-height: 100vh;
  }

  .Left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-shadow: inset 10px 0 30px 0 #dce7f9;
    background-color: #e9f2fe;
    width: 50%;
    order: 2;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
  }

  .SeparatorExtra {
    display: none
  }

}

@media(min-width: 860px){

  .SquareContainer{
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Square {
    display: inline;
    background-color: #ffffff;
    width: 40px;
    height: 40px;
    position: absolute;
    transform: rotate(45deg);
  }
}


@media (max-width: 350px) {
  .Container {
    margin: 0;
  }
}

@media (max-height: 370px) {
  .Left {
    height: auto;
    position: relative;
  }

  .SquareContainer{
    height: 100%;
  }
}