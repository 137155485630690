.InfoContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  background-color: #ffffff;
  box-shadow: 10px 10px 20px 0 #cfcfcf;
  background-color: #ffffff;
  border-radius: 10px;
}

.TextTitle {
  margin-top: 15px;
  font-family: 'ModernEraBold';
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #252525;
}

.PrincipalProduct {
  display: flex;
  margin-top: 15px;
}

.ImageProducts {
  width: 68px;
  height: 68px;
  border-radius: 10px;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #d8d8d8;
  margin-right: 15px;
}

.MarginImage {
  margin-right: 15px;
  width: 68px;
  height: 68px;
}

.Iva {
  margin: 7px 0 0;
  font-family: ModernEra;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d7278;
}

.TitlePrice {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 70%;
}

.NameProduct, .TagPrice, .NumberPrice {
   margin: 0;
}

.NameProduct {
  font-family: 'ModernEraBold';
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a2a2a;
}

.TagPrice, .NumberPrice {
  font-family: 'ModernEra';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #2a2a2a;
}

.Price {
 display: flex;
 justify-content: space-between;
 margin-top: 5px;
 width: 100%;
}

.FirstPrice {
 display: flex;
 justify-content: space-between;
 margin-top: 10px;
 width: 100%;
}

.PriceExtra {
 display: flex;
 justify-content: space-between;
 margin-top: 15px;
 width: 100%;
}

.Separator {
  border: solid 1px #e0e0e0;
  margin: 15px 0 0;
  width: 100%;
}

.NumberPrice > strike {
  margin-right: 4px;
}

.TextItem > strike {
  margin-right: 3px;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  max-width: 285px;
  width: 100%;
  padding-bottom: 17px;
}

.TitlePriceExtra {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.DeleteProduct {
  font-family: 'ModernEraBold';
  font-size: 15px;
  margin: 0;
  color: #2a2a2a;
  outline: none;
  cursor: pointer;
}

.TotalPrice {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.Send {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.SendItem {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0;
}

.SendItemFirst {
  display: flex;
  justify-content: space-between;
}

.TextItem {
  margin: 0;
  font-family: 'ModernEra';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a2a2a;
}

.TextItemBold {
  margin: 0;
  font-family: 'ModernEraBold';
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a2a2a;
}

.RightPrice {
  display: flex;
}

.Currency {
  margin: 4px 7px 0 0;
}

.TotalText {
  font-size: 15px;
}

.TotalNumber {
  font-size: 20px;
}

@media (max-width: 350px) {
  .InfoContainer {
    width: 300px;
  }
} 